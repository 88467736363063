<template>
  <div id="page-content-wrapper">
    <div
      class="bg-light page-header-wrapper border-bottom"
      style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;"
    >
      <div style="float: left;">
        Administration - Customers
      </div>
    </div>

    <div id="page-content" class="text-left">
      <b-card-group deck>
        <b-card
          header="Customers"
          style="max-width: 78rem;"
          class="mb-2"
          ref="formContainer5"
        >
          <div slot="header">
            Customers
            <b-button size="sm" variant="info" @click="addNewCustomer"
              >Add Customer</b-button
            >
          </div>
          <b-card-body>
            <b-table
              striped
              hover
              bordered
              small
              :sort-by="sortby"
              :items="allCustomers"
              :fields="fields"
              class="cy-table1 text-left"
            >
              <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                </b-button>
                <b-button size="sm" @click="editCustomer(row.item, row.index, $event.target)" class="mr-1">
                  Edit
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>CustomerID:</b></b-col>
                    <b-col>{{ row.item.customerid }}</b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>OrgID:</b></b-col>
                    <b-col>{{ row.item.orgid }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>TimeZone:</b></b-col>
                    <b-col>{{ row.item.timezone }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Api Server:</b></b-col>
                    <b-col>{{ row.item.apiserver }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Subscription URL:</b></b-col>
                    <b-col>{{ row.item.suburl }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Stats Server:</b></b-col>
                    <b-col>{{ row.item.statsserver }}</b-col>
                  </b-row>
                  <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                </b-card>
              </template>
              <template slot="actions" slot-scope="row">
                <b-button
                  size="sm"
                  @click="editCustomer(row.item, row.index, $event.target)"
                  class="mr-1"
                  disabled
                  >Edit</b-button
                >
              </template>
            </b-table>
          </b-card-body>
          <em slot="footer">Customers: {{ allCustomers.length }}</em>
        </b-card>
      </b-card-group>
      <b-modal
        class="text-left"
        :id="infoModal.id"
        ref="modal"
        :title="infoModal.title"
        ok-title="Save"
        @ok="saveCust"
      >
        <form ref="form">
          <b-form-group
            id="input-group-1"
            label="Customer Name"
            label-for="input-1"
          >
            <b-input
              id="input-1"
              v-model="infoModal.customer.customername"
            ></b-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Customer (Linux) Timezone"
            label-for="input-2"
          >
            <b-input
              id="input-2"
              v-model="infoModal.customer.timezone"
            ></b-input>
          </b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="infoModal.customer.customerenabled"
              name="checkbox-1"
            >
              Enabled
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-2"
              v-model="infoModal.customer.resubscribe"
              name="checkbox-1"
            >
              Resubscribe Webhooks on next poll (may take 15min)
            </b-form-checkbox>
        </form>
      </b-modal>
      <b-modal
        class="text-left"
        :id="infoModal2.id"
        ref="modal"
        :title="infoModal2.title"
        ok-title="Add"
        @ok="addCust"
      >
        <form ref="form">
          <b-form-group
            id="input-group-1"
            label="Customer Name"
            label-for="input-1"
          >
            <b-input
              id="input-1"
              v-model="infoModal2.customer.customername"
            ></b-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Customer (Linux) Timezone"
            label-for="input-2"
          >
            <b-input
              id="input-2"
              v-model="infoModal2.customer.timezone"
            ></b-input>
          </b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="infoModal2.customer.customerenabled"
              name="checkbox-1"
            >
              Enabled
            </b-form-checkbox>
            <b-form-select v-model="selectedIntegration" :options="allIntegrations" size="sm" class="mt-3"></b-form-select>
            <b-alert show size="sm" variant="info">Note: There is a limit of 4 customers per integration</b-alert>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ReportService from "@/service/ReportService";
export default {
  name: "CustomerAdmin",
  components: {
  },
  data: function() {
    return {
      allCustomers: [],
      selectedIntegration: null,
      allIntegrations: [
        { value: null, text: 'Please select an Integration' },
      ],
      integrationCount: {},
      sortby: 'customername',
      // fields: ["customername", "customerid", "customerenabled", "customerexpiry", "actions"],
      fields: [
        {
          key: "customername",
          sortable: true,
          label: "Customer Name"
        },
       {
          key: "customerenabled",
          sortable: true,
          label: "Enabled",
          tdClass: this.enabledClass,
        },
        {
          key: 'customerexpiry',
          label: 'Expiry',
          formatter: this.formatDate
        },
        {
          key: "integration",
          label: "Integration"
        },
        {
          key: "show_details",
          label: "Actions"
        }
      ],
      infoModal: {
        id: "info-modal",
        customer: {}
      },
      infoModal2: {
        id: "info-modal2",
        customer: {}
      }
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let loader = this.$loading.show({
        loader: "bars",
        color: "#A12321",
        isFullPage: false,
        container: this.$refs.formContainer5
      });
      this.integrationCount = {}
      ReportService.getAllCustomers(
        this.$auth.accessToken
      )
        .then(result => {
          this.allCustomers = result.data;
          result.data.forEach((customer) => {
            if (customer.clientid in this.integrationCount) {
              this.integrationCount[customer.clientid] += 1
            } else {
              this.integrationCount[customer.clientid] = 1
            }
          })
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    doToast(message, variant) {
      this.$bvToast.toast(message, {
        title: "Cloud Portal Admin",
        autoHideDelay: 5000,
        variant: variant,
        appendToast: true,
        toaster: "b-toaster-top-center"
      });
    },
    editCustomer(item) {
      this.infoModal.title = `Edit Customer`;
      this.infoModal.customer = {
        _id: item.customerid,
        customername: item.customername,
        customerenabled: item.customerenabled,
        timezone: item.timezone,
        resubscribe: item.resubscribe,
        action: "edit"
      };
      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    resetInfoModal() {},
    addNewCustomer() {
      this.selectedIntegration = null
      ReportService.getAllIntegrations(this.$auth.accessToken)
        .then(result => {
          this.allIntegrations = [ { value: null, text: 'Please select an Integration' }]
          result.data.forEach(el => {
            if (this.integrationCount[el.clientid] < 4 || !(el.clientid in this.integrationCount)) {
              this.allIntegrations.push({ value: el.clientid, text: el.name })
            }
          })

        })
      this.infoModal2.title = `Add New Customer`;
      this.infoModal2.customer = {
        customername: "",
        customerid: this.uuidv4(),
        customerenabled: true,
        timezone: "Pacific/Auckland",
        clientid: null
      };
      this.$root.$emit("bv::show::modal", this.infoModal2.id);
    },
    addCust(bvModalEvt) {
      bvModalEvt.preventDefault();
      ReportService.addNewCustomer(
        {
          customername: this.infoModal2.customer.customername,
          customerid: this.infoModal2.customer.customerid,
          customerenabled: this.infoModal2.customer.customerenabled,
          timezone: this.infoModal2.customer.timezone,
          clientid: this.selectedIntegration
        }, 
        this.$auth.accessToken
      ).then(() => {
        this.doToast(
              "Added Customer " + this.infoModal2.customer.customername,
              "success"
            );
            this.getData();
      })
      .catch(error => {
            console.error(error);
            this.doToast(
              "Failed to Update Customer " +
                this.infoModal.customer.customername +
                " " +
                error,
              "danger"
            );
          })
          .finally(() => {
            this.$root.$emit("bv::hide::modal", this.infoModal2.id);
          });
    },
    saveCust(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.infoModal.customer.customername === "") {
        this.$refs.modal.hide();
        this.$root.$emit("bv::hide::modal", this.infoModal.id);
        this.doToast("CustomerName is a required field", "warning");
        return false;
      }
      if (this.infoModal.customer.customerId === "") {
        this.$refs.modal.hide();
        return this.doToast("CustomerId is a required field", "warning");
      }
      
     
      if (this.infoModal.customer.action === "edit") {
        ReportService.saveCustomer(
          {
            _id: this.infoModal.customer._id,
            customername: this.infoModal.customer.customername,
            customerenabled: this.infoModal.customer.customerenabled,
            resubscribe: this.infoModal.customer.resubscribe,
            timezone: this.infoModal.customer.timezone
          },
          this.$auth.accessToken
        )
          .then(() => {
            this.doToast(
              "Updated Customer " + this.infoModal.customer.customername,
              "success"
            );
            this.getData();
          })
          .catch(error => {
            console.error(error);
            this.doToast(
              "Failed to Update Customer " +
                this.infoModal.customer.customername +
                " " +
                error,
              "danger"
            );
          })
          .finally(() => {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
          });
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    formatDate(timestamp) {
      return new Date(timestamp*1000).toLocaleDateString();
    },
    enabledClass(enabled) {
      return enabled ? "bg-success" : "bg-warning";
    }
  },
  watch: {
    selectedCustomer() {
      this.getData();
    }
  }
};
</script>
